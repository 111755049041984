/* eslint-disable react/no-danger */
import React from 'react';
import {
  Button,
  makeStyles,
  Typography,
} from '@material-ui/core';
import { Rating } from '@material-ui/lab';

const useStyles = makeStyles(() => ({
  rating: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: 25,
    marginLeft: 5,
    marginRight: 15,
  },
  button: {
    minHeight: 0,
    minWidth: 0,
    height: '50px',
    width: '50px',
    fontSize: '40px',
    padding: 0,
    // pointerEvents: 'auto',
  },
}));

function ButtonIcon({ value, onClick, formValue }) {
  const classes = useStyles();

  const renderEmoji = (value) => {
    if (value === 1) return <span>😃</span>;
    if (value === 2) return <span>😐</span>;
    if (value === 3) return <span>🙁</span>;
    return <span>🙂</span>;
  };

  return (
    <Button
      size="small"
      className={[classes.button, 'MuiRating-icon']}
      onClick={() => onClick(value)}
      style={{ backgroundColor: value === formValue ? 'rgba(0, 0, 0, 0.2)' : undefined }}
    >
      {renderEmoji(value)}
    </Button>
  );
}

function DynamicFormEmojiRating({ formik, label, code, hintText, onChange }) {
  const classes = useStyles();
  return (
    <>
      <Typography
        variant="h5"
        gutterBottom
        style={{ color: formik.touched[code] && Boolean(formik.errors[code]) ? 'red' : undefined }}
      >
        {label}
      </Typography>
      <Typography variant="body2" gutterBottom>
        {hintText}
      </Typography>
      <div style={{ margin: '0px 50px' }}>
        <div>
          <Rating
            defaultValue={2}
            max={3}
            value={formik.values[code]}
            onChange={(event, newValue) => {
              formik.handleChange({ target: { name: code, value: newValue } });
            }}
            IconContainerComponent={(props) => <ButtonIcon {...props} formValue={formik.values[code]} onClick={(value) => onChange(value)} />}
            className={classes.rating}
          />
        </div>

        <div style={{ display: 'flex', justifyContent: 'space-between', marginLeft: 3, marginRight: 5 }}>
          <Typography variant="body2" gutterBottom>Sehr gut</Typography>
          <Typography variant="body2" gutterBottom style={{ marginLeft: 15 }}>Okay</Typography>
          <Typography variant="body2" gutterBottom>Nicht so gut</Typography>
        </div>
      </div>
    </>
  );
}

export default DynamicFormEmojiRating;
