/* eslint-disable react/no-danger */
import React from 'react';
import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  makeStyles,
  TextField,
  Typography,
} from '@material-ui/core';

const useStyles = makeStyles(() => ({
  fieldRoot: {
    '&:not(:last-of-type)': {
      marginBottom: 16,
    },
  },
  formControlLabel: {
    marginBottom: '0px',
  },
  checkbox: {
    paddingTop: '4px',
    paddingBottom: '4px',
  },
  formControl: {
    marginLeft: 10,
    width: '100%',
  },
}));

function DynamicFormMultiSelect({ formik, label, code, hintText, hidden, params }) {
  const classes = useStyles();

  if (hidden) return null;

  if (params?.externalHeadline) {
    return (
      <div style={{ marginTop: 20 }}>
        {params?.showHorizontalRule && <hr />}
        <Typography
          variant="h5"
          gutterBottom
          style={{ marginTop: 20, color: formik.touched[code] && Boolean(formik.errors[code]) ? 'red' : undefined }}
        >
          {label}
        </Typography>
        <Typography variant="body2" gutterBottom>
          {hintText}
        </Typography>
        <FormControl component="fieldset" className={classes.formControl}>
          <FormGroup>
            {params.options.map((option) => (
              <FormControlLabel
                key={option}
                className={classes.formControlLabel}
                control={<Checkbox name={option} className={classes.checkbox} />}
                label={<Typography variant="body1" style={{ fontSize: '16px' }}>{option}</Typography>}
                checked={
                  (formik.values[code] || []).includes(option)
                  || ((formik.values[code] || []).find((val) => val.startsWith('Sonstiges:')) && option === 'Sonstiges:')
                }
                onChange={() => {
                  const value = formik.values[code] || [];
                  if (option === 'Sonstiges:' && !value.find((val) => val.startsWith('Sonstiges:'))) {
                    value.push(option);
                    formik.setFieldValue(code && code.toString(), value);
                    return;
                  }
                  if (option === 'Sonstiges:' && value.find((val) => val.startsWith('Sonstiges:'))) {
                    formik.setFieldValue(code && code.toString(), value.filter((opt) => !opt.startsWith('Sonstiges:')));
                    return;
                  }
                  if (!value.includes(option)) {
                    value.push(option);
                    formik.setFieldValue(code && code.toString(), value);
                    return;
                  }
                  formik.setFieldValue(code && code.toString(), value.filter((opt) => opt !== option));
                }}
              />
            ))}
            {(formik.values[code] || []).find((val) => val.startsWith('Sonstiges:')) && (
              <TextField
                className={classes.fieldRoot}
                style={{ margin: '0 32px' }}
                id={code && code.toString()}
                onChange={(e) => {
                  const value = formik.values[code] || [];
                  formik.setFieldValue(code && code.toString(), [
                    ...value.filter((opt) => !opt.startsWith('Sonstiges:')),
                    `Sonstiges: ${e.target.value}`,
                  ]);
                }}
              />
            )}
          </FormGroup>
        </FormControl>
      </div>
    );
  }

  return (
    <FormControl component="fieldset" className={classes.formControl}>
      <FormGroup>
        {params.options.map((option) => (
          <FormControlLabel
            key={option}
            className={classes.formControlLabel}
            control={<Checkbox name={option} className={classes.checkbox} />}
            label={<Typography variant="body1" style={{ fontSize: '16px' }}>{option}</Typography>}
            checked={(formik.values[code] || []).includes(option)}
            onChange={() => {
              const value = formik.values[code] || [];
              if (!value.includes(option)) {
                value.push(option);
                formik.setFieldValue(code && code.toString(), value);
                return;
              }
              formik.setFieldValue(code && code.toString(), value.filter((opt) => opt !== option));
            }}
          />
        ))}
      </FormGroup>
    </FormControl>
  );
}

export default DynamicFormMultiSelect;
